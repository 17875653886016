















import { get_device_locations } from "@/api";
import { deviceLocationsType } from "@/api/api.types";
import { Notif } from "@/store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "device-locations-search" })
export default class DevLocalSearch extends Vue {
  /* Constansts */

  @Prop({ required: true }) readonly value!: string;

  /* Variables */

  devLocalList =
    [] as deviceLocationsType["data"]["_embedded"]["deviceLocation"];

  devLocalLoading = false;

  search = null as unknown as string;

  asd = "";

  /* Computed */

  private get result(): string {
    return this.value || "";
  }

  private set result(value: string) {
    this.$emit("input", value);
  }

  /* Methods */

  async fetchDevLocal(): Promise<void> {
    try {
      this.devLocalLoading = true;
      const resp = await get_device_locations({
        search_device: this.value,
        order: "name",
        ascending: 1,
        limit: -1,
      });
      this.devLocalList = resp.data._embedded.deviceLocation;
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.devLocalLoading = false;
    }
  }

  /* Life-cycle Methods */
  mounted(): void {
    this.fetchDevLocal();
  }

  @Watch("search")
  onDeviceSearch(): void {
    this.fetchDevLocal();
  }
}
